import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './DoughSelector.styl';

import HeartIcon from '../../../icons/heart.svg';
import {useSelector} from "react-redux";

const cx = classNames.bind(styles);

export default function DoughSelector(props) {
  const {currentDough, onChange, className, types, specialStylesMode} = props;
  const isHalloweenMode = useSelector(state => !!state.city.userCity.is_halloween_mode_on);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  const foundIndex = types?.sort((a, b) => (a.label > b.label ? -1 : 1))?.findIndex(type => type?.id === currentDough);

  const overlayStyle = {
    width: `${100 / types.length}%`,
    transform: `translateX(${foundIndex === -1 ? 0 : foundIndex * 100}%)`,
  };

  const handleChange = type => {
    return type.id === currentDough ? false : onChange(type.id);
  };

  const isHeart = label =>
    label?.toLowerCase()?.includes('сердце') || label?.toLowerCase()?.includes('heart');

  const getLabel = label => {
    if (isHeart(label)) {
      return <HeartIcon className={cx('DoughSelector__heart')} />;
    }

    return label;
  };

  useEffect(() => {
    if (!currentDough && types[0]) {
      onChange(types[0].id);
    }
  }, []);

  return (
    <div className={cx('DoughSelector', className, {
      'DoughSelector__halloween': isHalloweenMode && specialStylesMode,
      'DoughSelector__new-year': isNewYearMode && specialStylesMode,
    })}>
      <div
        className={cx('DoughSelector__overlay')}
        style={types.length >= 3 ? undefined : overlayStyle}
      />
      {types
        .sort((a, b) => (a.label > b.label ? -1 : 1))
        .map(type => (
          <div
            key={type.id}
            className={cx('DoughSelector__type', {
              DoughSelector__type_selected: currentDough === type.id,
              DoughSelector__type_heart: isHeart(type.label),
              DoughSelector__type_threeselected: types.length >= 3 && currentDough === type.id,
            })}
            onClick={() => handleChange(type)}
            onKeyDown={e => e.key === 'Enter' && handleChange(type)}
            role="button"
            tabIndex={0}
          >
            {getLabel(type.label)}
          </div>
        ))}
    </div>
  );
}

DoughSelector.defaultProps = {
  className: '',
  types: [],
};

DoughSelector.propTypes = {
  className: PropTypes.string,
  types: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  currentDough: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
